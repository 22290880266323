<template>
  <b-card v-if="productData">
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row>
      <b-col
        md="6"
        lg="6"
      >
        <validation-observer

          ref="formProduct"
        > <b-form
          class="p-2"
        >
          <validation-provider
            #default="{errors}"
            :name="$t('Product Name')"
            rules="required"
          >
            <!-- Business Name -->
            <b-col cols="12">

              <b-form-group
                label="Product Name"
                label-for="product-name"
              >
                <b-form-input
                  id="product-name"
                  v-model="productData.name"
                  size="lg"
                  placeholder="Enter product name..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Business Name -->
          <!-- street address -->
          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="product-description"
            >
              <b-form-textarea
                id="product-description"
                v-model="productData.description"
                size="lg"
                rows="4"
                placeholder="Enter a description"
              />
            </b-form-group>
          </b-col>
          <!--/ street address -->
          <!-- Phone -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Unit price')"
            rules="required"
          >
            <b-col cols="12">
              <b-form-group
                label="Unit Price"
                label-for="price"
              >
                <cleave
                  id="price"
                  v-model="productData.price"
                  size="lg"
                  class="form-control"
                  :raw="true"
                  :options="currencyClaveOptions"
                  placeholder="Enter unit price..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Phone -->
          <!-- Email -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Cost')"
            rules="required"
          >
            <b-col cols="12">
              <b-form-group
                label="Cost"
                label-for="cost"
              >
                <cleave
                  id="cost"
                  v-model="productData.cost"
                  size="lg"
                  class="form-control"
                  :raw="true"
                  :options="currencyClaveOptions"
                  placeholder="Enter cost price..."
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </b-col>
          </validation-provider>
          <!--/ Email -->

          <!-- Website -->
          <b-col
            v-if="true==false"
            cols="12"
          >
            <b-form-group
              label="Unit Type"
              label-for="unit-type"
            >
              <v-select
                v-model="productData.unit_id"
                class="lg-input"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.app.globalParams.unitTypes"
                :reduce="val => val.value"
                autocomplete="chrome-off"
                :clearable="false"
                input-id="unit-id"
              />
            </b-form-group>
          </b-col>
          <!--/ Website -->
          <!-- Additional information -->
          <b-col cols="12">
            <b-form-checkbox
              v-model="productData.enable_taxes"
              checked="true"
              name="check-button"
              switch
              inline
              :value="true"
              :unchecked-value="false"
            >
              <h3>Enable taxes</h3>
            </b-form-checkbox>
            <span class="font-weight-bold d-block text-nowrap mt-1">Default taxes will be applied to this item.</span>
          </b-col>
          <!--/ street address -->

          <b-col
            cols="12"
            class="mt-3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="saveData"
            >
              Save
            </b-button>

          </b-col>
        </b-form>
        </validation-observer>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import productStoreModule from './productStoreModule'

export default {
  components: {
    BButton,
    BForm,
    vSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currencyClaveOptions: null,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      blankProductData: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        cost: 0,
        enable_taxes: true,
        unit_id: 4,
      },
      productData: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  created() {
    this.resetForm()
    this.refreshGlobalParams()
    this.currencyClaveOptions = this.$staticParams.currencyClaveOptions()
    let productId = 0

    this.productData.id = productId

    if (this.$router.currentRoute.params.id) {
      productId = this.$router.currentRoute.params.id
      this.fetchProduct(productId)
    }
    // console.log(this.productData)
  },
  methods: {
    fetchProduct(id) {
      store
        .dispatch('app-product/fetchSingleProduct', { id })//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.productData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    refreshGlobalParams() {
      store
        .dispatch('app/refreshGlobalParams')
    },
    saveData() {
      this.$refs.formProduct.validate().then(success => {
        if (success) {
          if (this.productData.id === 0) {
            store
              .dispatch('app-product/addProduct', this.productData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Product created'
                this.$router.push({ name: 'products' })
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            store
              .dispatch('app-product/updateProduct', this.productData)
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.isSuccess = true
                this.errorData = null
                this.successMsg = 'Product updated'
                this.$router.push({ name: 'products' })
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          }
        }
      })
    },
    resetForm() {
      this.productData = JSON.parse(JSON.stringify(this.blankProductData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-product'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
